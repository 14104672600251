import {
     MDBBtn, 
     MDBCol, 
     MDBContainer, 
     MDBIcon, 
     MDBInput, 
     MDBInputGroup, 
     MDBRange,
     MDBRow,
     MDBTextArea, 
     MDBTooltip, 
     MDBValidation,
     MDBValidationItem
    } from "mdb-react-ui-kit";
    import MDEditor, {
        commands,
        ICommand,
        TextState,
        TextAreaTextApi
      } from "@uiw/react-md-editor";
import { CirclePicker } from 'react-color';
import { addNewProperty, addProperty, alterElement, alterProperty, alterPropertyWithoutName, createProperty, getGlobalProperties, removeProperty } from "../../../functions/APICalls";


import './Animation.css';
import './ColorPicker.css';

function removePropertieFromElement(APIUrl, token, parentID, relationName, setError, changeValue){
    removeProperty(APIUrl, token, relationName, parentID)
    .then(res=>{
        changeValue("")
    })
    .catch(err=>{
        setError(err)
    })
}

function setPropertieToServer(APIUrl, token, parentID, propertyID, name, value, setError){
    
    if(value === ""){
        setError("insert Value")
        return
    }

    if(name==="name"){
        alterElement(APIUrl, token, parentID, encodeURI(value))
        .then(res=>{
            console.log(res)
        })
        .catch(err=>{
            setError("upload failed")
            return;
        })

        return;
    }

    if(propertyID===undefined){
        createProperty(APIUrl,token, name, parentID, encodeURI(value))
        .then(res=>{

            //console.log(res)
            addNewProperty(APIUrl, token, res.name, parentID, encodeURI(value))
            .then(newProp=>{
                //console.log(newProp)
                // alterProperty(APIUrl,token, newProp.id, newProp.name, value.replace("#", "%23"))
                // .then(data=>{
                //     //console.log(data)
                // }
                // ).catch(err=>{
                //     //console.log(err)
                //     setError("upload failed")
                // })
            })
            
            
        })

    }else{
        let normalizedValue = value.replace("#", "%23").replace('.','%2e')
        alterPropertyWithoutName(APIUrl,token, propertyID.replace('_','%5f'), encodeURI(value))
        .then(res=>{
            //console.log(res)
            
        }
        ).catch(err=>{
            //console.log(err)
            setError("upload failed")
            return;
        })
    }

}



export const stringEditor = (APIUrl, token, parentID, resetValue, propertyID, name, val, changeValue, error, setError) => {
    return(
        <MDBCol key={String(propertyID)+name} size='md' md={6}>
            <MDBContainer>
                <MDBValidation >
                    <MDBValidationItem tooltip  feedback={error} invalid>
                        <MDBInputGroup
                        noBorder
                        className="mb-3 " textAfter={
                            <>
                            <MDBTooltip tag={'a'} title="reset changes">
                                <MDBBtn 
                                className="mx-1 shadow-0 resetPropertie" 
                                onClick={()=>(changeValue(resetValue))}>
                                    <MDBIcon fa icon='fa-solid fa-arrow-rotate-left'/>
                                </MDBBtn>
                            </MDBTooltip>
                            <MDBTooltip tag={'a'} title="save changes">
                                <MDBBtn className={'shadow-0 setPropertie'} onClick={()=>(setPropertieToServer(APIUrl,token,parentID,propertyID,name,val, setError))}>
                                    <MDBIcon fa icon='fa-light fa-check'/>
                                </MDBBtn>
                            </MDBTooltip>

                            </>
                        }>
                            <MDBInput required label={name}  className='form-control' type='text' value={val} onChange={(e)=>changeValue(e.target.value)}/>
                        </MDBInputGroup>
                    </MDBValidationItem>
                </MDBValidation>
            </MDBContainer>
        </MDBCol>
    )
}

export const stringMarkdownEditor = (APIUrl, token, parentID, resetValue, propertyID, name, val, changeValue, error, setError) => {

    return(
        <MDBCol key={String(propertyID)+name} size='md' md={6}>
            <MDBContainer>
                <MDBValidation>
                    <MDBValidationItem tooltip  feedback={error} invalid>
                        <MDBInputGroup
                        noBorder
                        className="mb-3 " textAfter={
                            <>
                                <MDBTooltip tag={'a'} title="reset changes">
                                    <MDBBtn 
                                    className="mx-1 shadow-0 resetPropertie" 
                                    onClick={()=>(changeValue(resetValue))}>
                                        <MDBIcon fa icon='fa-solid fa-arrow-rotate-left'/>
                                    </MDBBtn>
                                </MDBTooltip>
                                <MDBTooltip tag={'a'} title="save changes">
                                    <MDBBtn className={'shadow-0 setPropertie'} onClick={()=>(setPropertieToServer(APIUrl,token,parentID,propertyID,name,val, setError))}>
                                        <MDBIcon fa icon='fa-light fa-check'/>
                                    </MDBBtn>
                                </MDBTooltip>
                            </>
                        }>
                            <div data-color-mode="light">
                                <MDEditor 
                                height={300} 
                                value={val} 
                                onChange={(e)=>changeValue(e)} 
                                commands={[
                                    commands.title, 
                                    commands.bold, 
                                    commands.italic,
                                    commands.hr, 
                                    commands.orderedListCommand, 
                                    commands.link
                                    ]}
                                />
                            </div>
                        </MDBInputGroup>
                    </MDBValidationItem>
                </MDBValidation>
            </MDBContainer>
        </MDBCol>
    )
}


export const stringIDEditor = ( name, val) => {
    return(
        <MDBCol  
        size='md' 
        md={6} >
            <MDBInput disabled label={name}  className='form-control'  type='text' value={val} />
        </MDBCol>
    )
}


/*This function needs several Parameter:
    - propertyID, used for changing and updating the data on the server
    - name the name of the property
    - val, the value item (Stored inside a react useState component)
    - changeVal, the function to change this value
    - pickerShow, a variable to show the color picker or not
    - changePickerShow, a function to change this variable

*/
export const colorEditor = (APIUrl, token, parentID, group, globalColors, setGlobalColors, resetValue, propertyID, name, val, changeValue, pickerShow, changePickerShow, error, setError) => {
    
    var c = val.substring(1);      // strip #
    var rgb = parseInt(c, 16);   // convert rrggbb to decimal
    var r = (rgb >> 16) & 0xff;  // extract red
    var g = (rgb >>  8) & 0xff;  // extract green
    var b = (rgb >>  0) & 0xff;  // extract blue
    var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
    if(!globalColors){
        getGlobalProperties(APIUrl, token, group)
        .then(data=>{
            setGlobalColors(data);
            }
        )
    }  
    return(
        <>
        <MDBCol key={String(propertyID)+name} size='md' md={6} className='d-flex'>
            <MDBContainer>
            <MDBRow className='bg-light mb-3'>
                <MDBValidation >
                    <MDBValidationItem  feedback={error} tooltip invalid>
                        <MDBInputGroup  
                        noWrap
                        noBorder
                        className="mb-3 shadow-0 "  
                        textBefore={
                            <MDBBtn color='none' className={'shadow-0'} tag="a" onClick={(e)=>(changePickerShow(!pickerShow))}>
                                {pickerShow
                                ?<MDBIcon fa icon="fa-solid fa-caret-up" />
                                :<MDBIcon fa icon="fa-solid fa-caret-down" />
                                }
                            </MDBBtn>
                        }
                        textAfter={
                            <>
                            <MDBTooltip tag={'a'} title="reset changes">
                                <MDBBtn 
                                className="mx-1 shadow-0 resetPropertie" 
                                onClick={()=>{
                                    console.log(resetValue)
                                    changeValue(resetValue)
                                    }}>
                                    <MDBIcon fa icon='fa-solid fa-arrow-rotate-left'/>
                                </MDBBtn>
                            </MDBTooltip>
                            <MDBTooltip tag={'a'} title="save changes">
                                <MDBBtn className={'shadow-0 setPropertie'} onClick={()=>(setPropertieToServer(APIUrl,token,parentID,propertyID,name,val, setError))}>
                                    <MDBIcon fa icon='fa-light fa-check'/>
                                </MDBBtn>
                            </MDBTooltip>
    
                            </>
                        }>
                            <MDBCol>
                                <MDBInput 
                                label={name}  
                                className='form-control' 
                                required
                                type='text'
                                value={(globalColors!==undefined && globalColors.filter(color=>color.value===val)[0]!==undefined)?globalColors.filter(color=>color.value===val)[0].name:val} 
                                style={{background:`linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, ${val} 50%, ${val} 100%)`}} 
                                />
                            </MDBCol>
                            {/* <MDBInput label={name}  className='form-control' type='text' placeholder="Recipient's username" value={val} style={{backgroundColor:`${val}`}} onChange={changeValue}/> */}
                        </MDBInputGroup>
                    </MDBValidationItem>
                </MDBValidation>
            </MDBRow>
            {pickerShow
            &&
            <MDBRow noWrap className="mb-3 animatedShow">
            <CirclePicker
            width="1"
            //PRESENTATION DISABLE
            colors={globalColors.map(globalProps=>{return(globalProps.value)})}
            onChangeComplete={(e)=>{
                changeValue(e.hex)
            }}
            ></CirclePicker>
            </MDBRow>
            }
            </MDBContainer>
        </MDBCol>
        </>
    )
}


export function numberEditor(APIUrl, token, parentID, resetValue, propertyID, name, val, changeValue, error, setError){
    return(
        <MDBCol key={String(propertyID)+name} size='md' md={6}>
        <MDBContainer>
        <MDBValidation key={String(propertyID)+name}>
            <MDBValidationItem  feedback={error} tooltip>
                <MDBInputGroup noBorder  className="mb-3" textAfter={
                <>
                <MDBTooltip tag={'a'} title="reset changes">
                    <MDBBtn 
                    className="mx-1 shadow-0 resetPropertie" 
                    onClick={()=>(removePropertieFromElement(APIUrl,token,parentID,name,setError, changeValue))}>
                        <MDBIcon fa icon='fa-solid fa-arrow-rotate-left'/>
                    </MDBBtn>
                </MDBTooltip>
                <MDBTooltip tag={'a'} title="save changes">
                    <MDBBtn className={'shadow-0 setPropertie'} onClick={()=>(setPropertieToServer(APIUrl,token,parentID,propertyID,name,val, setError))}>
                        <MDBIcon fa icon='fa-light fa-check'/>
                    </MDBBtn>
                </MDBTooltip>

                </>
            }>
                    <MDBInput required label={name} value={val} onChange={(e)=>changeValue(e.target.value)} id={String(propertyID)} type='number' />
                </MDBInputGroup>
            </MDBValidationItem>
        </MDBValidation>
        </MDBContainer>
        </MDBCol>
    )
}

export function rangeNumberEditor(APIUrl, token, parentID, resetValue, propertyID, name, val, changeValue, min, max, error, setError){
    return(
        <MDBCol key={String(propertyID)+name} size='md' md={6}>
        <MDBContainer>

            <label style={{scale:"0.8"}}>{name}</label>
            <MDBInputGroup noBorder className="mb-3" textAfter={
                        <>
                        <MDBTooltip tag={'a'} title="reset changes">
                            <MDBBtn 
                            className="mx-1 shadow-0 resetPropertie" 
                            onClick={()=>(removePropertieFromElement(APIUrl,token,parentID,name,setError, changeValue))}>
                                <MDBIcon fa icon='fa-solid fa-arrow-rotate-left'/>
                            </MDBBtn>
                        </MDBTooltip>
                        <MDBTooltip tag={'a'} title="save changes">
                            <MDBBtn className={'shadow-0 setPropertie'} onClick={()=>(setPropertieToServer(APIUrl,token,parentID,propertyID,name,val, setError))}>
                                <MDBIcon fa icon='fa-light fa-check'/>
                            </MDBBtn>
                        </MDBTooltip>

                        </>
                    }>
                <MDBRange label={name} defaultValue={val}  onChange={(e)=>changeValue(e.target.value)} id={String(propertyID)} />
            </MDBInputGroup>
        </MDBContainer>
        </MDBCol> 
    )
}

export function coordinateProperties(APIUrl, token, parentID, resetValue, propertyID, name, val, changeValue, error, setError){
    return(
        <MDBCol key={String(propertyID)+name} size='md' md={6} className={'d-flex align-items-center justify-content-center'}>
            <MDBInputGroup noBorder className="mb-3" textAfter={
                    <>
                        <MDBTooltip tag={'a'} title="reset changes">
                            <MDBBtn 
                            className="mx-1 shadow-0 resetPropertie" 
                            onClick={()=>(console.log())}>
                                <MDBIcon fa icon='fa-solid fa-arrow-rotate-left'/>
                            </MDBBtn>
                        </MDBTooltip>
                        <MDBTooltip tag={'a'} title="save changes">
                            <MDBBtn className={'shadow-0 setPropertie'} onClick={()=>(setPropertieToServer(APIUrl,token,parentID,propertyID,name,val, setError))}>
                                <MDBIcon fa icon='fa-light fa-check'/>
                            </MDBBtn>
                        </MDBTooltip>

                    </>
                    }>
                <input label={name} value={val} onChange={(e)=>changeValue(e.target.value)} id={String(propertyID)} type='number' />
            </MDBInputGroup>
        </MDBCol>
    )

}